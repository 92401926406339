body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.loader{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader span{
width: 30px !important;
height: 30px !important;
border-radius: 100%;
border-width: 4px !important;
border-style: solid;
border-color: #88ac2e #88ac2e transparent !important;
border-image: initial;
display: inline-block;
animation: 0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip;
}
.loader.inner-loader{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader.inner-loader span{
width: 30px !important;
height: 30px !important;
border-radius: 100%;
border-width: 4px !important;
border-style: solid;
border-color: #88ac2e #88ac2e transparent !important;
border-image: initial;
display: inline-block;
animation: 0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip;
}